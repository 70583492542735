import { useStaticQuery, graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { openModal, closeModal } from "@redq/reuse-modal";
import Container from "common/components/UI/Container";
import Text from "common/components/Text";
import Button from "common/components/Button";
import GatsbyImage from "common/components/GatsbyImage";
import Image from "common/components/Image";
import Section, {
  BannerContentWrapper,
  BannerContent,
  VideoWrapper,
  Buttons,
  Figure,
} from "./banner.style";
import playIcon from "common/assets/image/saasAppCreative/icons/play.svg";
import bubble1 from "common/assets/image/saasAppCreative/banner-bubble-1.png";
import bubble2 from "common/assets/image/saasAppCreative/banner-bubble-2.png";
import bubble3 from "common/assets/image/saasAppCreative/banner-bubble-3.png";
import group1 from "common/assets/image/saasAppCreative/Group 8.png";
import group2 from "common/assets/image/saasAppCreative/Group 6.png";
import group3 from "common/assets/image/saasAppCreative/Group 3.png";
import wallpaperDesktop from "common/assets/image/ezom/wallpaper-desktop.png";
import { EZOM_APP_LINK } from "../Navbar";
import { rgb } from "polished";

// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://www.youtube.com/embed/hW98BFnVCm8"
      frameBorder="0"
    />
  </VideoWrapper>
);

const Banner = () => {
  const [domLoaded, setDomLoaded] = useState(false);
  // modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: "video-modal",
        disableDragging: true,
        default: {
          width: "auto",
          height: "auto",
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  const data = useStaticQuery(graphql`
    query {
      dashboard: file(relativePath: { eq: "image/ezom/Main Page 2.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  return (
    <Section
      id="home"
    >
      <Container width="1440px">
        <BannerContentWrapper>
          <BannerContent>
            <h2
              className="animate__animated animate__fadeInUp"
              style={{ color: "#fff" }}
            >
              The Eazy Order Management app for your e-commerce success
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width="303"
              height="18"
              viewBox="0 0 305 20"
              fill="none"
              className="text-vector"
            >
              <path
                d="M304 5.89906C241.336 4.56553 179.21 2.07903 116.431 1.52566C80.3447 1.20758 -27.5718 0.27647 8.1484 2.07233C66.861 5.02416 127.541 4.92931 186.656 6.91433C209.56 7.68344 232.281 8.71436 255.069 9.80391C263.839 10.2232 270.688 10.9317 256.655 10.663C194.024 9.46344 131.718 6.79769 69.0856 5.50859C62.4019 5.37103 42.5996 5.30398 49.0375 5.93811C72.636 8.26254 97.9526 9.17828 122.094 10.4677C151.165 12.0205 180.707 12.9902 209.536 15.0364C219.892 15.7714 233.761 16.0007 243.403 17.4964C260.464 20.1432 206.042 18.5154 187.336 18.3945C156.204 18.1935 125.109 17.5739 94.0043 17.145"
                stroke="white"
                stroke-linecap="round"
              />
            </svg>
            </h2>

            <Text
              className="animate__animated animate__fadeInUp"
              content="Join Australia's leading e-commerce businesses that use EzOM to"
            />
            <Text
              className="animate__animated animate__fadeInUp"
              content="manage inventories and fulfill orders."
            />
            {/*<Buttons>*/}
            <Button
              style={{
                marginTop: 36,
                backgroundColor: "#252525",
                borderRadius: 500,
                color: "#fff",
                fontSize: 18,
                minWidth: "323px",
                minHeight: "61px",
              }}
              title="Start to fulfill orders!"
              onClick={() => (window.location = EZOM_APP_LINK)}
            />

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="198"
              height="93"
              viewBox="0 0 198 93"
              fill="none"
              className="arrow-vector"
            >
              <path
                d="M249 52.749C240.757 26.5731 236.056 12.8684 208.752 18.924C195.073 21.9577 189.687 28.4407 189.687 41.1216C189.687 49.5516 202.397 47.6582 202.397 39.0076C202.397 27.7191 197.882 16.7121 188.628 9.52826C167.014 -7.24843 131.146 11.8117 115.545 27.3802C109.347 33.566 107.657 50.9409 119.782 43.823C128.345 38.7959 123.156 24.101 117.663 18.924C92.7825 -4.52795 62.8022 20.8231 44.8163 38.7726C38.7728 44.8039 26.8522 58.6516 24.5744 66.6078C22.7282 73.0564 8.75119 51.774 16.9248 62.2622C21.6548 68.3316 31.1485 77.1623 39.285 78.1176C43.2098 78.5784 21.3975 83.4678 18.1017 84.9296C12.572 87.3822 -0.20266 94.0139 3.74413 86.5739C7.01194 80.4138 11.7468 69.9486 11.7468 63.3193"
                stroke="#EC5151"
                stroke-width="5"
                stroke-linecap="round"
              />
            </svg>
          </BannerContent>
          <Figure id="hero-banner" className="hero-banner">
            <GatsbyImage
              src={
                (data.dashboard !== null) | undefined
                  ? data.dashboard.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="dashboard"
            />
            <Image
              src={group1}
              alt="bubble 1"
              className={`banner-bubble bubble-1 ${domLoaded ? "active" : ""}`}
            />
            <Image
              src={group2}
              alt="bubble 2"
              className={`banner-bubble bubble-2 ${domLoaded ? "active" : ""}`}
            />
            <Image
              src={group3}
              alt="bubble 3"
              className={`banner-bubble bubble-3 ${domLoaded ? "active" : ""}`}
            />
          </Figure>
        </BannerContentWrapper>
      </Container>
    </Section>
  );
};

export default Banner;
