import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Link from "common/components/Link";
import Text from "common/components/Text";
import Image from "common/components/Image";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import Section, {
  SectionHeading,
  Grid,
  Item,
  SectionInfoImage,
  SectionInfo,
} from "./howItWorks.style";
import rightArrow from "common/assets/image/saasAppCreative/icons/right-arrow.svg";
import group from "common/assets/image/saasAppCreative/Group 14.png";

const HowItWorks = () => {
  const data = useStaticQuery(graphql`
    query {
      saasAppCreativeJson {
        howTos {
          icon {
            publicURL
          }
          id
          link
          linkLabel
          text
          title
        }
      }
    }
  `);
  return (
    <>
      <Section id="how-to">
        <SectionHeading>
          <h4>Let’s explore how it works</h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="305"
            height="12"
            viewBox="0 0 305 12"
            fill="none"
          >
            <path
              d="M304 3.7217C241.336 2.98085 179.21 1.59946 116.431 1.29204C80.3447 1.11532 -27.5718 0.598039 8.1484 1.59574C66.861 3.23564 127.541 3.18295 186.656 4.28574C209.56 4.71302 232.281 5.28576 255.069 5.89106C263.839 6.124 270.688 6.51763 256.655 6.36832C194.024 5.70191 131.718 4.22094 69.0856 3.50477C62.4019 3.42835 42.5996 3.3911 49.0375 3.7434C72.636 5.03475 97.9526 5.54349 122.094 6.25985C151.165 7.12249 180.707 7.66123 209.536 8.79799C219.892 9.20634 233.761 9.33374 243.403 10.1647C260.464 11.6351 206.042 10.7308 187.336 10.6636C156.204 10.5519 125.109 10.2077 94.0043 9.96944"
              stroke="white"
              stroke-linecap="round"
            />
          </svg>
        </SectionHeading>
      </Section>
      <SectionInfo>
        <Grid>
          {data.saasAppCreativeJson.howTos.map((howTo) => (
            <Item key={howTo.id}>
              <figure>
                <Image src={howTo.icon.publicURL} alt="icon" />
              </figure>
              <div className="itemWrapper">
                <Heading as="h4" content={howTo.title} />
                <Text content={howTo.text} />
              </div>
              {/* <Link href={howTo.link}>
                  {howTo.linkLabel}
                  <img src={rightArrow} alt="right arrow icon" />
                </Link> */}
            </Item>
          ))}
        </Grid>
        <SectionInfoImage>
          <Image src={group} />
        </SectionInfoImage>
      </SectionInfo>
    </>
  );
};

export default HowItWorks;
