import React, { useState } from "react";
import PropTypes from "prop-types";
import TabWrapper, {
  TabMenu,
  MenuItem,
  TabContent,
  TabPanel,
} from "./tabs.style";
import Image from "common/components/Image";
import { Icon } from "react-icons-kit";
import { smallRight } from "react-icons-kit/entypo/smallRight";
import { star } from "react-icons-kit/entypo/star";
import { quoteLeft } from "react-icons-kit/fa/quoteLeft";

const Tab = ({ active, className, children }) => {
  const [state, setState] = useState({
    active: active || 0,
  });

  const handleChange = (index) => {
    setState({ active: index });
  };

  const addAllClasses = ["rq_tab"];
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <TabWrapper className={addAllClasses.join(" ")}>
      <TabMenu className="tab_menu">
        {children.map((element, index) => {
          let activeClass = index === state.active ? "active" : "";
          return (
            <MenuItem
              key={index}
              className={activeClass}
              onClick={() => handleChange(index)}
            >
                <Image src={element.avatar.publicURL} className="tab_image" />
                <span className="menuText">{element.name}</span>
                <div className="menuIcon">
                  <Icon icon={smallRight} size={64}/>
                </div>

            </MenuItem>
          );
        })}
      </TabMenu>
      <TabContent className="tab_content">
        <div style={{ color: "white" }} className="contentIcon">
          <Icon icon={quoteLeft} size={64} />
        </div>
        <div className="title-text">{children[state.active]?.title}</div>
        <div className="content-text">{children[state.active]?.content}</div>
        <div style={{ color: "#FFC859", letterSpacing: "2px" }}>
          {Array(children[state.active]?.stars)
            .fill(1)
            .map((item, index) => (
              <Icon key={index} icon={star} size={24} style={{margin: "2px"}}/>
            ))}
        </div>
      </TabContent>
    </TabWrapper>
  );
};

export const Panel = ({ children }) => (
  <TabPanel className="tab_panel">{children}</TabPanel>
);

Tab.propTypes = {
  children: PropTypes.array,
  active: PropTypes.number.isRequired,
};

Panel.propTypes = {
  children: PropTypes.element,
  title: PropTypes.element.isRequired,
};

export default Tab;
