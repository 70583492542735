import "@redq/reuse-modal/es/index.css";
import "animate.css";
import { ResetCSS } from "common/assets/css/style";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { theme } from "common/theme/saasAppCreative";
import Seo from "components/seo";
import Banner from "containers/SaasAppCreative/Banner";
import Clients from "containers/SaasAppCreative/Clients";
import Features from "containers/SaasAppCreative/Features";
import Footer from "containers/SaasAppCreative/Footer";
import HowItWorks from "containers/SaasAppCreative/HowItWorks";
import Navbar from "containers/SaasAppCreative/Navbar";
import {
  ContentWrapper,
  GlobalStyle,
} from "containers/SaasAppCreative/saasAppCreative.style";
import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import Review from "../containers/SaasAppCreative/Review";
import News from "../containers/SaasAppCreative/News";

const SaasAppCreative = () => {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <Seo title="EzOM | Eazy Order Management" />
        {/*<Modal />*/}
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          {/* <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
          ></Sticky> */}
          <DrawerProvider>
            <Navbar />
          </DrawerProvider>
          <Banner />
          <Clients />
          <HowItWorks />
          <Features />
          <Review />
          <News />
          {/* <AnalyticsTool /> */}

          {/* <Portfolio /> */}

          {/* <SystemMonitoring /> */}
          {/* <WorldMap /> */}
          {/* <NewsFeed /> */}
          {/* <CallToAction /> */}
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default SaasAppCreative;
