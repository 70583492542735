import React from "react";
import Section, {
  JoinEmail,
  ListNews,
  NewItem,
  NewItemInfo,
  NewItemInfoExtra,
  NewItemInfoTitle,
  NewsButton,
  NewsSecond,
  SectionHeading,
  Wrapper,
  ContactInfo,
  InfoItem,
  NewInfoImage,
  EnterEmail,
  ContentEmail,
  ButtonEmail,
} from "./news.style";
import { graphql, useStaticQuery } from "gatsby";
import Image from "common/components/Image";
import { Icon } from "react-icons-kit";
import { ic_grid_view } from "react-icons-kit/md/ic_grid_view";
import { calendar } from "react-icons-kit/fa/calendar";
import { arrowRight } from "react-icons-kit/fa/arrowRight";
import newsletter from "common/assets/image/saasAppCreative/newsletter.png";
import { ic_place } from "react-icons-kit/md/ic_place";
import { paperPlane } from "react-icons-kit/fa/paperPlane";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";

const News = () => {
  const data = useStaticQuery(graphql`
    query {
      saasAppCreativeJson {
        news_article {
          contactInfo {
            address
            email
            openingTime
            phone
            title
          }
          news {
            id
            title
            thumbnail {
              publicURL
            }
            category
            createdAt
          }
        }
      }
    }
  `);

  const news_article = data.saasAppCreativeJson.news_article;
  return (
    <Wrapper id="resources">
      <Section>
        <SectionHeading>
          <h4>
            Our Latest news <br /> & Article
          </h4>
        </SectionHeading>
      </Section>
      <ListNews>
        {news_article.news.map((item) => (
          <NewItem key={item.id}>
            <Image src={item.thumbnail.publicURL} />
            <NewItemInfo>
              <div className="text">
                <Icon icon={ic_grid_view} />
                <span>{item.category}</span>
              </div>
              <NewItemInfoTitle>{item.title}</NewItemInfoTitle>
              <NewItemInfoExtra>
                <span className="createdAt">
                  <Icon icon={calendar} />
                  <span>{item.createdAt}</span>
                </span>
                <p>
                  Read more
                  <Icon icon={arrowRight} />
                </p>
              </NewItemInfoExtra>
            </NewItemInfo>
          </NewItem>
        ))}
      </ListNews>
      <NewsSecond>
        <NewInfoImage>
          <Image src={newsletter} />
        </NewInfoImage>
        {/* <ContactInfo>
          <Heading as="h4" content={news_article.contactInfo.title} />
          <InfoItem>
            <Icon icon={paperPlane} size={22} />
            <a href={`mailto:${news_article.contactInfo.email}`}>
              <Text content={news_article.contactInfo.email} />
            </a>
          </InfoItem>
        </ContactInfo> */}
        <ContactInfo>
          <h2
            className="animate__animated animate__fadeInUp"
            style={{ color: "#314559" }}
          >
            Subscribe to our newsletter
          </h2>
          <Text
            className="animate__animated animate__fadeInUp"
            content="Get email updates with our latest shipping products tips and advice."
          />
          <EnterEmail>
            <ContentEmail>
              <p>Enter your mail</p>
            </ContentEmail>
            <ButtonEmail>Join Now</ButtonEmail>
          </EnterEmail>
        </ContactInfo>
      </NewsSecond>
    </Wrapper>
  );
};

export default News;
