import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Container from "common/components/UI/Container";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Text from "common/components/Text";
import Box from "common/components/Box";
import Section, {
  SectionHeading,
  Grid,
  FeatureCard,
  SectionInfoButton,
} from "./features.style";

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      saasAppCreativeJson {
        features {
          id
          title
          text
          icon {
            publicURL
          }
        }
      }
    }
  `);
  const features = data.saasAppCreativeJson.features;
  return (
    <>
      <Section id="how-to">
        <SectionHeading>
          <h4>What the features of product</h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="305"
            height="12"
            viewBox="0 0 305 12"
            fill="none"
          >
            <path
              d="M304 3.7217C241.336 2.98085 179.21 1.59946 116.431 1.29204C80.3447 1.11532 -27.5718 0.598039 8.1484 1.59574C66.861 3.23564 127.541 3.18295 186.656 4.28574C209.56 4.71302 232.281 5.28576 255.069 5.89106C263.839 6.124 270.688 6.51763 256.655 6.36832C194.024 5.70191 131.718 4.22094 69.0856 3.50477C62.4019 3.42835 42.5996 3.3911 49.0375 3.7434C72.636 5.03475 97.9526 5.54349 122.094 6.25985C151.165 7.12249 180.707 7.66123 209.536 8.79799C219.892 9.20634 233.761 9.33374 243.403 10.1647C260.464 11.6351 206.042 10.7308 187.336 10.6636C156.204 10.5519 125.109 10.2077 94.0043 9.96944"
              stroke="white"
              stroke-linecap="round"
            />
          </svg>
        </SectionHeading>
      </Section>
      <Grid>
        {features.map((feature) => (
          <FeatureCard key={feature.id}>
            <Image src={feature.icon.publicURL} alt="feature icon" />
            <Box>
              <div style={{ width: 350, marginTop: 45 }}>
                <Heading as="h4" content={feature.title} />
                <Text as="p" content={feature.text} />
              </div>
            </Box>
          </FeatureCard>
        ))}
      </Grid>
      <SectionInfoButton>See All Feature</SectionInfoButton>
    </>
  );
};

export default Features;
