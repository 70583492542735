import React from "react";
import Section, {
  SectionHeading,
  SectionInfo,
  SectionInfoButton,
  SectionInfoDesc,
  SectionInfoImage,
  SectionInfoText,
  SectionInfoTitle,
} from "./clients.style";
import Image from "common/components/Image";
import market from "common/assets/image/saasAppCreative/Market.png";
const Clients = () => {
  return (
    <>
      <Section id="intergration">
        <SectionHeading>
          <h4>
            Our pre-built integrations with EzOM Fulfillment makes setup easy
          </h4>
        </SectionHeading>
      </Section>
      <SectionInfo>
        <SectionInfoTitle>
          Easy Intergration - Save Time
          <div className="vector">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="503"
              height="13"
              viewBox="0 0 503 13"
              fill="none"
              className="text-vector"
            >
              <path
                d="M501.974 3.99387C398.366 3.17894 295.649 1.65941 191.851 1.32124C132.187 1.12686 -46.2401 0.557843 12.819 1.65532C109.893 3.45921 210.221 3.40124 307.96 4.61431C345.83 5.08433 383.395 5.71433 421.073 6.38017C435.572 6.6364 446.896 7.06939 423.694 6.90515C320.142 6.1721 217.126 4.54303 113.571 3.75525C102.521 3.67118 69.7799 3.63021 80.4243 4.01774C119.441 5.43822 161.299 5.99784 201.215 6.78584C249.281 7.73473 298.124 8.32735 345.789 9.57779C362.911 10.027 385.843 10.1671 401.783 11.0812C429.992 12.6986 340.012 11.7038 309.084 11.63C257.612 11.5071 206.199 11.1285 154.771 10.8664"
                stroke="url(#paint0_linear_1_124)"
                stroke-linecap="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_1_124"
                  x1="1"
                  y1="1"
                  x2="7.7019"
                  y2="64.0179"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF6939" />
                  <stop offset="0.0001" stop-color="#FF3D00" />
                  <stop offset="1" stop-color="#F3AB3F" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </SectionInfoTitle>

        <SectionInfoDesc>
          Join Australia's leading e-commerce businesses that use EzOM to manage
          inventories and fulfill orders. Less integration hassle. Less overhead
          costs. Higher data consistency. Consistent web look and feel in our
          software to track financials and inventory. Clearly understand your
          business performance and inventory assets Record freight and sales
          data in Xero Update your sales channel with inventory in Xero Import
          sales receipts or invoices from Xero into EzOM.app for shipping.
        </SectionInfoDesc>
        <SectionInfoText>See The EzOM.app In Action</SectionInfoText>
      </SectionInfo>
      <SectionInfoButton>TALK TO AN EXPERT</SectionInfoButton>
      <SectionInfoImage>
        <div className="imageWrapper">
          <Image src={market} />
        </div>
      </SectionInfoImage>
    </>
  );
};

export default Clients;
