import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const Section = styled.section`
  margin-top: 118px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(134deg, #314559 0%, #314559 0.01%, #f9d0c4 100%);
  height: 172px;
  @media only screen and (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .container {
    @media only screen and (max-width: 1366px) {
      max-width: 1170px;
    }
    @media only screen and (max-width: 1024px) {
      max-width: 960px;
    }
  }
`;

export const SectionHeading = styled.div`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  width: 1104px;
  h4 {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 60px;
    letter-spacing: 0.96px;

    @media screen and (max-width: 1536px) {
      font-size: 44px !important;
    }
    @media screen and (max-width: 1280px) {
      font-size: 36px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 40px !important;
    }
    @media screen and (max-width: 768px) {
      font-size: 32px !important;
    }
    @media screen and (max-width: 480px) {
      font-size: 18px !important;
      padding-top: 20px;
    }
  }
`;

export const SectionInfo = styled.div`
  margin-top: 54px;
  padding: 0 147px 0 180px;
  @media screen and (max-width: 900px) {
    padding: 50px;
  }
  .text-vector {
    @media screen and (max-width: 480px) {
      display: none;
    }
  }
`;

export const SectionInfoTitle = styled.span`
  color: #181414;
  text-align: center;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 123.5%; /* 49.4px */
  letter-spacing: 0.8px;
  position: relative;
  .vector {
    position: absolute;
    top: 30px;
    right: 23%;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  @media screen and (max-width: 1536px) {
    font-size: 40px !important;
  }
  @media screen and (max-width: 1280px) {
    font-size: 36px !important;
  }
  @media screen and (max-width: 1024px) {
    font-size: 32px !important;
  }
  @media screen and (max-width: 768px) {
    font-size: 28px !important;
  }
  @media screen and (max-width: 480px) {
    font-size: 20px !important;
  }
`;

export const SectionInfoDesc = styled.p`
  color: #696969;
  font-family: ABeeZee;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-align: left;
  @media screen and (max-width: 480px) {
    font-size: 15px !important;
  }
`;

export const SectionInfoText = styled.span`
  font-family: ABeeZee;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
  @media screen and (max-width: 480px) {
    font-size: 15px !important;
  }

`;

export const SectionInfoButton = styled.button`
  border-radius: 20px;
  background: #314559;
  padding: 7px 12px;
  display: block;
  margin: 0 auto;
  margin-top: 35px;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
`;

export const SectionInfoImage = styled.div`
  margin-top: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  .imageWrapper {
    max-width: 60%;
    @media screen and (max-width: 900px) {
      width: 85% !important;
    }
  }
`;
export default Section;
