import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const Section = styled.section`
  margin-top: 205.55px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(134deg, #314559 0%, #314559 0.01%, #f9d0c4 100%);
  height: 172px;
  @media only screen and (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .container {
    @media only screen and (max-width: 1366px) {
      max-width: 1170px;
    }
    @media only screen and (max-width: 1024px) {
      max-width: 960px;
    }
  }
`;

export const SectionHeading = styled.div`
  position: relative;
  svg {
    position: absolute;
    top: 66%;
    left: 50%;
    transform: translateX(-50%);
    width: 303px;
    height: 10px;
    @media screen and (max-width: 480px) {
      display: none;
    }
  }
  h4 {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 62.4px;
    letter-spacing: 0.96px;

    @media screen and (max-width: 1536px) {
      font-size: 44px !important;
    }
    @media screen and (max-width: 1280px) {
      font-size: 36px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 40px !important;
    }
    @media screen and (max-width: 768px) {
      font-size: 32px !important;
    }
    @media screen and (max-width: 480px) {
      font-size: 24px !important;
    }
  }
`;


export const SectionInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 900px){
    flex-direction: column;
  }
`;

export const Grid = styled.div`
  // padding: 0 0 0 78px;
  gap: 60px;
  display: block;
  margin-bottom: -68px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 100px;
  @media (max-width: 1280px) {
    gap: 18px;
  }
  @media (max-width: 1024px) {
    gap: 20px;
    margin-top: 60px;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 290px);
    gap: 50px 40px;
  }
  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 60px;
    padding: 0 20px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: start;
  gap: 41px;
  margin-bottom: 68px;
  @media only screen and (max-width: 900px) {
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 480px) {
    // max-width: 260px;
  }
  @media only screen and (max-width: 768px) {
    text-align: center;
    margin: 0 auto;
    align-items: center;
    margin-bottom: 20px;
  }
  figure {
    margin: 0 0 30px;
    @media (min-width: 769px) and (max-width: 1024px) {
      min-height: auto;
      margin-bottom: 30px;
    }
    @media (max-width: 480px) {
      margin-bottom: 20px;
      min-height: auto;
    }
  }
  img {
    @media (max-width: 1024px) {
      max-width: 70px;
    }
    @media (max-width: 768px) {
      margin: 0 auto;
    }
  }
  .itemWrapper {
    width: 381px;
    @media only screen and (max-width: 480px) {
     width: auto;
    }
  }
  h4 {
    color: #000;
    font-weight: 900;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    line-height: 39px;
    letter-spacing: 0.6px;
    @media (max-width: 1024px) {
      font-size: 17px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
  p {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0.36px;
    @media (min-width: 769px) and (max-width: 1024px) {
      font-size: 14px;
      line-height: 1.9;
    }
    @media (max-width: 480px) {
      font-size: 15px;
    }
  }
  a {
    color: ${themeGet('colors.linkColor')};
    font-family: Manrope, sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.1px;
    @media (min-width: 769px) and (max-width: 1024px) {
      font-size: 14px;
    }
    img {
      line-height: 1;
      margin-left: 6px;
      transform: translateX(2px);
      transition: 0.3s ease 0s;
    }
    &:hover img {
      transform: translateX(5px);
    }
  }
`;

export const SectionInfoImage = styled.div`
  margin-top: 113px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    @media screen and (max-width: 900pxpx) {
      width: 80%;
    }
    @media screen and (max-width: 480px) {
      width: 80%;
    }
  }
`;

export default Section;
