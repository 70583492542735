import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const Section = styled.section`
  margin-top: 243px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(134deg, #314559 0%, #314559 0.01%, #f9d0c4 100%);
  height: 172px;
  @media only screen and (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .container {
    @media only screen and (max-width: 1366px) {
      max-width: 1170px;
    }
    @media only screen and (max-width: 1024px) {
      max-width: 960px;
    }
  }
`;

export const SectionHeading = styled.div`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  position: relative;
  svg {
    position: absolute;
    top: 66%;
    left: 50%;
    transform: translateX(-50%);
    width: 303px;
    height: 10px;
    @media screen and (max-width: 480px) {
      display: none;
    }
  }
  h4 {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 62.4px;
    letter-spacing: 0.96px;

    @media screen and (max-width: 1536px) {
      font-size: 44px !important;
    }
    @media screen and (max-width: 1280px) {
      font-size: 36px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 40px !important;
    }
    @media screen and (max-width: 768px) {
      font-size: 32px !important;
    }
    @media screen and (max-width: 480px) {
      font-size: 24px !important;
    }
  }
`;
export const Grid = styled.div`
  gap: 70px 50px;
  display: grid;
  margin-top: 102px;
  @media only screen and (max-width: 767px) {
    gap: 20px;
  }
  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (min-width: 1024px) {
    gap: 40px 30px;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  @media only screen and (max-width: 1024px) {
    text-align: center;
    img {
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 480px) {
    img {
      max-width: 65px;
    }
  }
  @media only screen and (min-width: 1280px) {
    grid-template-columns: 80px 1fr;
  }
  h4 {
    
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px; /* 100% */
    letter-spacing: 0.48px;
  }
  p {
    text-align: center;
    color: #696969;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 0.32px;
  }
`;

export const SectionInfoButton = styled.button`
  border-radius: 500px;
  border: 1px solid #4F5C6C;
  padding: 17px 39px;
  display: block;
  margin: 0 auto;
  margin-top: 45px;
  color: #35485C;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
`;
export default Section;

