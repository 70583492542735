import React from "react";
import Section, { SectionHeading } from "./review.style";
import Tab from "common/components/Tabs";
import { graphql, useStaticQuery } from "gatsby";

const Review = () => {
  const data = useStaticQuery(graphql`
    query {
      saasAppCreativeJson {
        reviews {
          id
          name
          title
          avatar {
            publicURL
          }
          content
          stars
        }
      }
    }
  `);
  const reviews = data.saasAppCreativeJson.reviews;
  return (
    <>
      <Section id="pricing">
        <SectionHeading>
          <h4>What our Clients Say</h4>
        </SectionHeading>
      </Section>
      <Tab active={0} children={reviews} />
    </>
  );
};

export default Review;
