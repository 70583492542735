import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const Section = styled.section`
text-align:center;
  margin-top: 200px;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
`;

export const SectionHeading = styled.div`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  h4 {
    color: #204945;
    font-family: Anybody;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.02em;
    text-align: center;
    

    @media screen and (max-width: 1536px) {
      font-size: 44px !important;
    }
    @media screen and (max-width: 1280px) {
      font-size: 36px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 40px !important;
    }
    @media screen and (max-width: 768px) {
      font-size: 32px !important;
    }
    @media screen and (max-width: 480px) {
      font-size: 24px !important;
    }
  }
`;


export default Section;
