import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { rgba } from "polished";

export const Wrapper = styled.div`
  margin-bottom: 50px;
`;

const Section = styled.section`
  margin-top: 305px;
  border-radius: 20px;
  background: linear-gradient(134deg, #314559 0%, #314559 0.01%, #f9d0c4 100%);
  height: 288px;
  @media only screen and (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .container {
    @media only screen and (max-width: 1366px) {
      max-width: 1170px;
    }
    @media only screen and (max-width: 1024px) {
      max-width: 960px;
    }
  }
`;

export const SectionHeading = styled.div`
  padding-top: 30px;
  h4 {
    margin: 0;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 55px;
    letter-spacing: -0.096px;
    @media screen and (max-width: 1536px) {
      font-size: 44px !important;
    }
    @media screen and (max-width: 1280px) {
      font-size: 36px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 40px !important;
    }
    @media screen and (max-width: 768px) {
      font-size: 32px !important;
    }
    @media screen and (max-width: 480px) {
      font-size: 24px !important;
    }
  }
`;

export const ListNews = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-top: -100px;
  z-index: 99;
  position: relative;
  @media screen and (max-width: 768px) {
    padding: 0 30px;
  }
`;

export const NewItem = styled.div`
  width: 313px;
  height: 373px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 11px 0px rgba(153, 153, 153, 0.3);
  position: relative;
`;

export const NewItemInfo = styled.div`
  padding: 15px 20px 16px 20px;
  .text {
    i {
      color: #5228b6;
    }
    span {
      color: #787878;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.028px;
      margin: 0;
      margin-left: 5px;
      @media screen and (max-width: 480px) {
        font-size: 10px !important;
      }
    }
  }
`;

export const NewItemInfoTitle = styled.p`
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.032px;
  margin: 0;
  margin-top: 15px;
  @media screen and (max-width: 480px) {
    font-size: 12px !important;
  }
`;

export const NewItemInfoExtra = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 10px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
  .createdAt {
    display: flex;
    align-items: center;
    gap: 5px;
    i {
      color: #888;
    }
    span {
      color: #888;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.028px;
      @media screen and (max-width: 480px) {
        font-size: 10px !important;
      }
    }
  }

  p {
    color: #5228b6;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.028px;
    margin-left: 69px;
    @media screen and (max-width: 768px) {
      margin-left: unset;
    }
    // display: flex;
    align-items: center;
    @media screen and (max-width: 480px) {
      font-size: 10px !important;
    }
    i {
      margin-left: 5px;
    }
  }
`;

export const NewsSecond = styled.div`
  margin-top: 61px;
  display: flex;
  justify-content: space-evenly;
  @media screen and (max-width: 900px){
    flex-direction: column;
  }
`;
export const NewInfoImage = styled.div`
  margin-top: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContactInfo = styled.div`
  margin-top: 108px;
  h2 {
    color: #314559;
    text-align: center;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: 62px;
    letter-spacing: -0.07px;
    @media screen and (max-width: 1536px) {
      font-size: 44px !important;
    }
    @media screen and (max-width: 1280px) {
      font-size: 36px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 40px !important;
    }
    @media screen and (max-width: 768px) {
      font-size: 32px !important;
    }
    @media screen and (max-width: 480px) {
      font-size: 24px !important;
    }
  }
  p {
    color: #666768;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.032px;
    text-align: center;
    // width: 471px;
    @media screen and (max-width: 480px) {
      font-size: 14px;
      line-height: 1.6;
    }
  }
`;
export const EnterEmail = styled.div`
  width: 500px;
  height: 50px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  @media only screen and (max-width: 480px) {
    width: 370px;
  }
  margin: 60px auto 0 auto;
  p {
    text-align: unset;
  }
`;
export const ContentEmail = styled.div`
  width: 340px;
  height: 50px;
  padding-left:20px;
  p {
    color: #314559;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 169.231% */
    letter-spacing: -0.031px;
  }
`;
export const ButtonEmail = styled.button`
  width: 160px;
  height: 50px;
  border-radius: 12px;
  border: 0;
  background: #f9d0c4;
  color: #314559;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.043px;
`;

export default Section;
