import styled from "styled-components";

const TabWrapper = styled.div`
  margin-top: 54px;
  display: flex;
  padding: 0 53px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const TabMenu = styled.ul`
  width: 30%;
  margin-bottom: -9px;
  @media screen and (max-width: 768px) {
    display: flex;
    gap: 10px;
    width: 100%;
  }
`;

export const MenuItem = styled.li`
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  height: 150px;
  align-items: center;
  border-radius: 15px;
  background: linear-gradient(90deg, #314559 0%, rgba(254, 254, 254, 0) 100%);
  margin-bottom: 9px;
  @media screen and (max-width: 768px) {
    background: linear-gradient(to top, #314559 0%, rgba(254, 254, 254, 0) 100%);
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    img {
      max-width: 50px;
    }
  }
  @media only screen and (max-width: 1024px) {
    text-align: center;
    img {
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 480px) {
    img {
      max-width: 30px;
    }
  }
  @media only screen and (min-width: 1280px) {
    grid-template-columns: 80px 1fr;
  }
  &.active {
    font-weight: 700;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    background: linear-gradient(
      to left,
      #314559 0%,
      rgba(74, 91, 109, 0.88) 28.13%,
      rgba(152, 162, 172, 0.5) 67.71%,
      rgba(255, 255, 255, 0) 100%
    );
    @media screen and (max-width: 768px) {
      background: linear-gradient(
        to top,
        #314559 0%,
        rgba(74, 91, 109, 0.88) 28.13%,
        rgba(152, 162, 172, 0.5) 67.71%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  .menuText {
    color: #23262f;
    font-family: Anybody;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 109.091% */
    @media screen and (max-width: 1536px) {
      font-size: 22px !important;
    }
    @media screen and (max-width: 1280px) {
      font-size: 20px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 18px !important;
    }
  }
  .menuIcon {
    i svg {
      @media screen and (max-width: 768px) {
        transform: rotate(90deg);
      }
    }
  }
`;

export const TabContent = styled.div`
  z-index: 20;
  width: 70%;
  border-radius: 0px 21px 21px 0;
  background: linear-gradient(90deg, #314559 0%, #4a5666 16.15%, #f9d0c4 100%);
  @media screen and (max-width: 768px) {
    background: linear-gradient(to bottom, #314559 0%, #4a5666 16.15%, #f9d0c4 100%);
    width: 100%;
    border-radius: 0 0 21px 21px;
    padding: 10px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  .content-text {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 145.455% */
    @media screen and (max-width: 1536px) {
      font-size: 22px !important;
    }
    @media screen and (max-width: 1280px) {
      font-size: 20px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 18px !important;
    }
    @media screen and (max-width: 768px) {
      font-size: 16px !important;
    }
    @media screen and (max-width: 480px) {
      font-size: 14px !important;
    } 
  }
  .title-text {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px; /* 145.455% */
    @media screen and (max-width: 1536px) {
      font-size: 22px !important;
    }
    @media screen and (max-width: 1280px) {
      font-size: 20px !important;
    }
    @media screen and (max-width: 1024px) {
      font-size: 18px !important;
    }
    @media screen and (max-width: 768px) {
      font-size: 16px !important;
    }
    @media screen and (max-width: 480px) {
      font-size: 14px !important;
    } 
  }

  .contentIcon {
    i svg {
      @media screen and (max-width: 480px) {
        height: 10px !important;
        width: 10px !important;
      }
 }
 }
`;

export const TabPanel = styled.div``;

export default TabWrapper;
